<template>
  <div class="w-100">
        <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead class="thead-dark">
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="8%" class="text-center">Codigo</th>
                    <th width="34%" class="text-center">Nombre</th>
                    <th width="7%" class="text-center">UM</th>
                    <th width="10%" class="text-center">Cantidad</th>
                    <th width="13%" class="text-center">P. Unit.</th>
                    <th width="13%" class="text-center">P. Total</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in sale_detail" :key="item.id_product">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.code }}</td>
                      <td class="align-middle text-left">{{ item.name }}</td>
                      <td class="align-middle text-center">{{ item.unit_measure }}</td>
                      <td class="align-middle text-center">
                        <input type="number" @change="EditSaleDetail(item.id_product)" :ref="'dsquantity'+item.id_product" class="form-control text-center" v-model="item.quantity">
                      </td>
                      <td class="align-middle text-center">
                        <input type="number" @change="EditSaleDetail(item.id_product)" step="any" :ref="'dsunitprice'+item.id_product" class="form-control text-right" v-model="item.unit_price">
                      </td>
                      <td class="align-middle text-right">{{ item.total_price }}</td>
                      <td class="align-middle text-center">
                        <button type="button" @click="DeleteSaleDetail(item.id_product)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>  
                      </td>

                  </tr>
                </tbody>
              </table>
            </div>


  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from '@/assets/js/EventBus';

export default {
  name: "UsuarioList",
  data() {
    return {
      sale_detail: [],
    };
  },
  mounted() {
    EventBus.$on('AddSaleDetail', (sale_detail) => {
      this.AddSaleDetail(sale_detail)
    });
  
  },
  methods: {
    ListSaleDetail,
    AddSaleDetail,
    EditSaleDetail,
    DeleteSaleDetail,
 
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};


function ListSaleDetail() {
  this.sale_detail
  EventBus.$emit('SaleAmount',this.sale_detail);
}

function AddSaleDetail(sale_detail) {

  let add = true;
  let me = this;
  for (var i = 0; i < me.sale_detail.length; i++) {
    if (me.sale_detail[i].id_product == sale_detail.id_product) {
      // EN CASO SE ECNEUTRE REGISTRADO ACTUALIZAR DATOS
      let quantity = parseFloat(me.sale_detail[i].quantity) + parseFloat(sale_detail.quantity); 
      let total_price = parseFloat(me.sale_detail[i].unit_price) * parseFloat(quantity);
      total_price = total_price.toFixed(2);
     
      me.sale_detail[i].quantity = quantity;
      me.sale_detail[i].total_price = total_price;
      add = false;
      break;
    }
  }

  if(add){
    this.sale_detail.push(sale_detail) 
  }

  EventBus.$emit('SaleAmount',this.sale_detail);
}

function EditSaleDetail(id_product) {

  let quantity = this.$refs['dsquantity'+id_product][0]['value'];
  let unit_price = this.$refs['dsunitprice'+id_product][0]['value'];
  let total_price = 0;
  quantity = parseFloat(quantity);
  unit_price = parseFloat(unit_price);
  total_price = unit_price * quantity;

  let me = this;
  for (var i = 0; i < me.sale_detail.length; i++) {
    if (me.sale_detail[i].id_product == id_product) {
      
      unit_price = unit_price.toFixed(2);
      total_price = total_price.toFixed(2);
      me.sale_detail[i].quantity = quantity;
      me.sale_detail[i].unit_price = unit_price;
      me.sale_detail[i].total_price = total_price;
      break;
    }
  }

  EventBus.$emit('SaleAmount',this.sale_detail);
}

function DeleteSaleDetail(id_product) {

  let me = this;
  for (var i = 0; i < me.sale_detail.length; i++) {
    if (me.sale_detail[i].id_product == id_product) {
      me.sale_detail.splice(i, 1);
      break;
    }
  }

  EventBus.$emit('SaleAmount',this.sale_detail);
}
</script>
