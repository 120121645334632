<template>
  <div>
    <b-modal size="md" hide-footer v-model="modalClients" class="" title="NUEVO CLIENTE">
      <b-row>
        <b-col md="12">
          <b-form id="Form" @submit.prevent="AddClient">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Tipo de Documento :">
                    <select
                      ref="document_type"
                      class="form-control"
                      v-model="client.document_type"
                    >
                      <option value="1">DNI</option>
                      <option value="6">RUC</option>
                      <option value="0">OTROS</option>
                    </select>
                    <small
                      v-if="errors.document_type"
                      class="form-text text-danger"
                      >Seleccione un tipo de documento</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input
                        v-model="client.document_number"
                        class="form-control"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="info">
                          <b-icon icon="search"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errors.document_number"
                      class="form-text text-danger"
                      >Ingrese un nro de documento</small
                    >
                  </b-form-group>
                </b-col>
            

                <b-col md="12">
                  <b-form-group label="Nombres / Razón Social:" required>
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="client.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre o razón social</small
                    >
                  </b-form-group>
                </b-col>
     

                <b-col md="12">
                  <b-form-group label="Ubigeo :" label-for="input-1">
                    <select
                      ref="ubigee"
                      v-model="client.ubigee"
                      class="form-control"
                    >
                      <option value="">-- Seleccione --</option>
                      <option value="1">Tacna</option>
                    </select>
                  </b-form-group>
                </b-col>
      

                <b-col md="12">
                  <b-form-group label="Dirección :" required>
                    <b-form-input
                      type="text"
                      ref="address"
                      v-model="client.address"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
    



                <b-col md="12">
                  <b-form-group label="Teléfono :" required>
                    <b-form-input
                      type="text"
                      ref="phone"
                      v-model="client.phone"
                      placeholder="Ingrese su Telefono"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
      
                <b-col md="12">
                  <b-form-group label="Email :" required>
                    <b-form-input
                      type="email"
                      ref="email"
                      v-model="client.email"
                      placeholder="Ingrese su email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="client.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
 
                <b-col md="6">
                  <b-button
                    type="reset"
                    class="form-control bg-danger text-white"
                    variant="danger"
                    >LIMPIAR</b-button
                  >
                </b-col>
                <b-col md="6">
                  <b-button
                    type="submit"
                    class="form-control bg-primary text-white"
                    variant="primary"
                    >GUARDAR</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
        </b-col>
  
      </b-row>
    </b-modal>
  </div>
</template>



<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";

export default {
  name: "ClientsProduct",
  data() {
    return {
        modalClients: false,
        client: {
        document_type: "1",
        document_number: "",
        name: "",
        ubigee: "",
        address: "",
        phone: "",
        email: "",
        state: 1,
      },

      ubigens: [],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
      },
      validate: false,
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalClientsShow', () => {
      this.modalClients = true;
    });
  },
  methods: {
    AddClient,
    Validate,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};


function AddClient() {
  // validacion de campos obligatorios
  this.Validate();
  if (this.validate) {
    return false;
  }

  let me = this;
  let url = this.url_base + "client/add/";
  let data = {
    document_type: this.client.document_type,
    document_number: this.client.document_number,
    name: this.client.name,
    ubigee: this.client.ubigee,
    address: this.client.address,
    phone: this.client.phone,
    email: this.client.email,
    state: this.client.state,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: "Cliente",
      role: 2,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {

        me.client.document_type = 1;
        me.client.document_number = "";
        me.client.name = "";
        me.client.ubigee = "";
        me.client.address = "";
        me.client.phone = "";
        me.client.email = "";
        me.client.state = 1;
        EventBus.$emit('ListClients');
        Swal.fire("Cliente", "Se ha creado el nuevo cliente", "success");
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  this.errors.document_type = this.client.document_type.length == 0 ? true : false;
  this.errors.document_number = this.client.document_number.length == 0 ? true : false;
  this.errors.name = this.client.name.length == 0 ? true : false;

  this.validate = this.errors.document_type == true ? true : false;
  this.validate = this.errors.document_number == true ? true : false;
  this.validate = this.errors.name == true ? true : false;

}
</script>
