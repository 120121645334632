<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Venta - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="AddSale">
              <b-row>
                
                <b-col md="3">
                  <b-form-group >
                    <select
                      ref="id_warehouse"
                      v-model="sale.id_warehouse"
                      class="form-control"
                    >
                      <option
                        v-for="item of warehouses"
                        :key="item.id_warehouse"
                        :value="item.id_warehouse"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <small
                      v-if="errors.id_warehouse"
                      class="form-text text-danger"
                      >Seleccione un almacen</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <select ref="coin" v-model="sale.coin" class="form-control">
                      <option value="PEN">Soles</option>
                      <option value="USD">Dólares</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>
                <b-col md="3">
                  <b-button
                    class="form-control btn btn-info"
                    variant="info"
                    @click="modalProducts"
                    >Agregar Productos</b-button
                  >
                </b-col>

                <b-col class="mt-3" md="12"></b-col>

                <b-col md="3">
                  <b-form-group label="Tipo de Comprobante :">
                    <select
                      ref="invoice_type"
                      v-model="sale.invoice_type"
                      class="form-control"
                      @change="ListSeries"
                    >
                      <option value="01">Factura</option>
                      <option selected value="03">Boleta</option>
                      <option value="07">Nota de Crédito</option>
                      <option value="08">Nota de Débito</option>
                      <option value="NV">Nota de Venta</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Serie :">
                    <select
                      ref="id_serie"
                      v-model="sale.id_serie"
                      class="form-control"
                    >
                      <option value="">-- Seleccione --</option>
                      <option v-for="item of series" :key="item.id_serie"  
                      :value="item.id_serie" > {{item.serie +' - '+ item.number}}</option>
                    </select>
                    <small v-if="errors.serie" class="form-text text-danger"
                      >Seleccione una serie</small
                    >
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input
                      type="date"
                      ref="broadcast_date"
                      v-model="sale.broadcast_date"
                    ></b-form-input>
                    <small
                      v-if="errors.broadcast_date"
                      class="form-text text-danger"
                      >Seleccione una fecha</small
                    >
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Forma de Pago :">
                    <select
                      ref="payment"
                      v-model="sale.payment"
                      @change="Payment"
                      class="form-control"
                    >
                      <option value="01-008">Contado - Efectivo</option>
                      <option value="01-001">Contado - Depósito en Cuenta</option>
                      <option value="01-002">Contado - Giro</option>
                      <option value="01-003">Contado - Transferencia de Fondos</option>
                      <option value="01-004">Contado - Orden de Pago</option>
                      <option value="01-005">Contado - Tarjeta de Débito</option>
                      <option value="03-7">Crédito - 7 Dias</option>
                      <option value="03-15">Crédito - 15 Dias</option>
                      <option value="03-30">Crédito - 30 Dias</option>
                      <option value="03-45">Crédito - 45 Dias</option>
                      <option value="03-60">Crédito - 60 Dias</option>
                      <option value="03-75">Crédito - 75 Dias</option>
                      <option value="03-90">Crédito - 90 Dias</option>
                    </select>
                  </b-form-group>
                </b-col>
       

                <b-col md="6">
                  <b-form-group>
                    <label
                      >Cliente: <span @click="modalClients" class="text-info link">Nuevo</span></label
                    >
                    <select
                      ref="id_client"
                      v-model="sale.id_client"
                      class="form-control"
                    >
                      <option value="">Venta del Dia - 00000000</option>
                      <option
                        v-for="item of clients"
                        :key="item.id_client"
                        :value="item.id_client"
                      >
                        {{ item.name + " - " + item.document_number }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Dirección :" required>
                    <b-form-input
                      type="text"
                      ref="client_address"
                      v-model="sale.client_address"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Detalle venta -->
                <AddSaleDetail/>
                <!-- Detalle venta -->

    
                <b-col md="12" class="mt-3"></b-col>

                <b-col md="8">
                  <b-form-group
                  class="m-0"
                  >
                    <b-form-input readonly v-model="number_to_letters" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                  class="m-0"
                    label-cols-lg="8"
                    label="Subtotal:"
                    label-align="right"
                  >
                    <b-form-input readonly class="text-right" v-model="sale.subtotal"></b-form-input>
                  </b-form-group>
                  <b-form-group
                  class="m-0"
                    label-cols-lg="8"
                    label="IGV (18%):"
                    label-align="right"
                  >
                    <b-form-input readonly class="text-right" v-model="sale.igv"></b-form-input>
                  </b-form-group>
                  <b-form-group
                  class="m-0"
                    label-cols-lg="8"
                    label="Total:"
                    label-align="right"
                  >
                    <b-form-input readonly class="text-right" v-model="sale.total"></b-form-input>
                  </b-form-group>
                </b-col>


                
                <b-col md="3"></b-col>
                <b-col md="3">
                  <b-button
                    type="reset"
                    class="form-control bg-danger text-white"
                    variant="danger"
                    >LIMPIAR</b-button
                  >
                </b-col>
                <b-col md="3">
                  <b-button
                    type="submit"
                    class="form-control bg-primary text-white"
                    variant="primary"
                    >GUARDAR</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Products -->

    <!-- Modal Clients -->
    <ModalClients />
    <!-- Modal Clients -->

    
  </div>
</template>

<style>
/* div.modal-backdrop {
    opacity: .5;
}
.btn-info{
  background: red !important;
  color:#fff !important;
} */
</style>
<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import converter from "@/assets/js/NumberToLetters";
import { mapState } from "vuex";


// components
import ModalClients from './components/ModalClients'
import ModalProducts from './components/ModalProduct'
import AddSaleDetail from './components/AddSaleDetail'

export default {
  name: "UsuarioAdd",
  components:{
      ModalProducts,
      AddSaleDetail,
      ModalClients,
  },
  data() {
    return {
      sale: {
        id_sale: "",
        id_client: "",
        id_serie: "",
        id_warehouse: 1,
        operation_type: "001",
        invoice_type: "03",
        serie: "",
        broadcast_date: moment(new Date()).local().format("YYYY-MM-DD"),
        broadcast_time: "",
        expiration_date: moment(new Date()).local().format("YYYY-MM-DD"),
        coin: "PEN",
        payment: "01-008",
        payment_type: "01",
        payment_method: "008",
        payment_deadline: "0",
        observation: "",
        modified_document_type: "",
        modified_string: "",
        modified_number: "",
        modified_emission_date: "",
        reason: "",
        support: "",
        sunat_message: "",
        hash_cpe: "",
        hash_cdr: "",
        taxed_operation: '0.00',
        exonerated_operation: '0.00',
        unaffected_operation: '0.00',
        discount: '0.00',
        subtotal: '0.00',
        igv: '0.00',
        total: '0.00',
        state: '1',
      },

      series: null,
      warehouses: [],
      clients: [],

      // modal products
      search_product:'',
      modal_products: [],

      detail_sale: [],
      number_to_letters: 'SON: CERO SOLES',
      //errors
      errors: {
        name: false,
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    EventBus.$on('SaleAmount', (sale_detail) => {
      this.SaleAmount(sale_detail);
    });
    EventBus.$on('ListClients', () => {
      this.ListClients();
    });
    this.ListWarehouses();
    this.ListClients();
    this.ListSeries();
  },
  methods: {
    ListWarehouses,
    ListClients,
    ListSeries,
    modalProducts,
    modalClients,

    Payment,
    SaleAmount,
    AddSale,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

//listar clientes
function ListClients() {
  let me = this;
  let url = this.url_base + "active-clients";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.clients = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//listar almacenes
function ListWarehouses() {

  let me = this;
  let url = this.url_base + "active-warehouses/"+this.id_establishment;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.warehouses = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//listar series
function ListSeries() {
  let me = this;
  let url = this.url_base + "active-series/"+this.id_establishment+"/"+this.sale.invoice_type;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.series = response.data.result;
        if (response.data.result.length > 0) {
          let mseries = response.data.result;
          mseries = mseries[0]['id_serie'];          
          me.sale.id_serie = mseries;
        }else{
          me.sale.id_serie = '';
        }
        

        // console.log(seriesaa[0]['id_serie'])
        // let id_serie = series[0].id_serie;
        // alert(series)
          // me.sale.id_serie = me.series[index]['id_serie'];
        
        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      console.log(error);
    });
}

function modalProducts() {
  EventBus.$emit('ModalProductsShow');
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function Payment() {
    let me = this;
    let payment = this.sale.payment.split('-');

    if (payment[0] == '01') {
      me.sale.payment_type = payment[0];
      me.sale.payment_method = payment[1];
      me.sale.payment_deadline = '0';
      me.sale.expiration_date = moment(new Date()).local().format("YYYY-MM-DD");
    }
    if (payment[0] == '03') {
      me.sale.payment_type = payment[0];
      me.sale.payment_method = '';
      me.sale.payment_deadline = payment[1];
      me.sale.expiration_date = moment(me.sale.broadcast_date, "YYYY-MM-DD").add(parseInt(payment[1]) , 'day').local().format("YYYY-MM-DD");
    }
  
}

function SaleAmount(sale_detail) {

  let me = this;
  this.sale_detail = sale_detail;

  let taxed_operation = 0;
  let exonerated_operation = 0;
  let unaffected_operation = 0;
  let discount = 0;
  let subtotal = 0;
  let igv = 0;
  let total = 0;

  for (var i = 0; i < me.sale_detail.length; i++) {

    if (me.sale_detail[i].igv == '10') {
      taxed_operation += parseFloat(me.sale_detail[i].total_price);
    }
    if (me.sale_detail[i].igv == '20') {
      exonerated_operation += parseFloat(me.sale_detail[i].total_price);
    }
    if (me.sale_detail[i].igv == '30') {
      unaffected_operation += parseFloat(me.sale_detail[i].total_price);
    }

  }

  total = taxed_operation + exonerated_operation + unaffected_operation;
  igv = taxed_operation - (taxed_operation / 1.18);
  subtotal = total - igv;

  me.sale.taxed_operation = taxed_operation.toFixed(2);
  me.sale.exonerated_operation = exonerated_operation.toFixed(2);
  me.sale.unaffected_operation = unaffected_operation.toFixed(2);
  me.sale.subtotal = subtotal.toFixed(2);
  me.sale.igv = igv.toFixed(2);
  me.sale.total = total.toFixed(2);

  // console.log(Hola.HelloWord());
  
  me.number_to_letters =  'SON: '+ converter.NumberToLetters(total.toFixed(2));

}










function AddSale() {
  // validacion de campos obligatorios
  // this.Validate();
  // if (this.validate) {
  //   return false;
  // }

  let me = this;
  let url = this.url_base + "sale/add/";
  let data = {
        id_sale : me.sale.id_sale,
        id_client : me.sale.id_client,
        id_serie : me.sale.id_serie,
        id_warehouse : me.sale.id_warehouse,
        operation_type : me.sale.operation_type,
        invoice_type : me.sale.invoice_type,
        serie : me.sale.serie,
        broadcast_date : me.sale.broadcast_date,
        broadcast_time : me.sale.broadcast_time,
        expiration_date : me.sale.expiration_date,
        coin : me.sale.coin,
        payment : me.sale.payment,
        payment_type : me.sale.payment_type,
        payment_method : me.sale.payment_method,
        payment_deadline : me.sale.payment_deadline,
        observation : me.sale.observation,
        modified_document_type : me.sale.modified_document_type,
        modified_string : me.sale.modified_string,
        modified_number : me.sale.modified_number,
        modified_emission_date : me.sale.modified_emission_date,
        reason : me.sale.reason,
        support : me.sale.support,
        sunat_message : me.sale.sunat_message,
        hash_cpe : me.sale.hash_cpe,
        hash_cdr : me.sale.hash_cdr,
        taxed_operation : me.sale.taxed_operation,
        exonerated_operation : me.sale.exonerated_operation,
        unaffected_operation : me.sale.unaffected_operation,
        discount : me.sale.discount,
        subtotal : me.sale.subtotal,
        igv : me.sale.igv,
        total : me.sale.total,
        state : me.sale.state,

        sale_detail : me.sale_detail,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: "Venta",
      role: 2,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          me.ListClients();
          me.ListSeries();
          Swal.fire("Venta", "Se ha registrado la nueva venta", "success");
      } else {
        Swal.fire("Venta", response.data.response, "warning");
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  // this.errors.name = this.sale.name.length == 0 ? true : false;
  // this.errors.state = this.sale.state.length == 0 ? true : false;
  // this.validate = this.errors.name == true ? true : false;
  // this.validate = this.errors.state == true ? true : false;
}

</script>
